body {
    @media screen and (max-device-width: 320px) {
        font-size: 0.9rem;
    }

    div.slideHomePanel {
        position: absolute;
        width: 100%;
        background: #0B3544;
        height: 123px;
        z-index: 100;
        transition: all 1.5s ease 0s;
        color: white;
        bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        input:focus, textarea:focus, select:focus {
            outline: none;
        }

        &.minimize {
            height: 0px;
        }

        &.open {
            transition: all 1.5s ease 0s;
            overflow-y: visible;
            height: calc(100% - 117px);

            .content {
                .brandsShips {
                    height: calc(100vh - 305px);
                }
            }
        }
        /*       @media screen and (max-device-height: 568px) {
            &.open {
                .content {
                    .brandsShips {
                        height: calc(100vh - 288px);
                    }
                }
            }
        }

        @media screen and (min-device-height: 736px) {
            &.open {
                height: calc(100% - 170px);

                .content {
                    .brandsShips {
                        height: 47vh;
                    }
                }
            }
        }

        @media screen and (min-device-height: 812px) {
            &.open {
                height: calc(100% - 250px);

                .content {
                    .brandsShips {
                        height: 47vh;
                    }
                }
            }
        }

        @media screen and (min-device-height: 1024px) {
            &.open {
                height: calc(100% - 450px);

                .content {
                    .brandsShips {
                        height: 37vh;
                    }
                }
            }
        }

        @media screen and (min-device-height: 1366px) {
            &.open {
                height: calc(100% - 800px);

                .content {
                    .brandsShips {
                        height: 27vh;
                    }
                }
            }
        }
*/
        .box-white {
            border: 1px solid white;
            border-radius: 8px;
            padding: 3px 5px;
            text-align: left;
        }

        .slideCircleButton {
            background-color: #0B3544;
            width: 76px;
            height: 76px;
            border-radius: 50%;
            font-size: 45px;
            position: absolute;
            top: -60px;
            margin-left: 10px;
            text-align: center;
        }

        .header {
            height: 24px;
            text-align: center;

            .slideButton {
                background-color: rgba(255,255,255,.6);
                display: inline-block;
                width: 40px;
                height: 6px;
                border-radius: 6px;
            }
        }

        .content {
            padding: 0 10px;
            color: white;
            text-transform: uppercase;
            text-align: left;

            .rowContent {
                padding-top: 10px;
                display: flex;

                .search {
                    @extend .box-white;
                    display: flex;
                    flex-grow: 1;
                    align-items: center;

                    i {
                        &.icon-search {
                            margin-right: 5px;
                        }

                        &.clearText {
                            opacity: .6;
                            cursor: pointer;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }

                .statusContent {
                    display: flex;

                    .status {
                        @extend .box-white;
                        display: flex;
                        min-width: 120px;
                        align-items: center;
                        margin-left: 10px;

                        i {
                            margin-left: auto;
                            font-size: 25px;
                            font-weight: bold;
                            cursor: pointer;
                        }
                    }
                }


                .txtsearch {
                    background-color: transparent;
                    border: none;
                    color: white;
                    flex-grow: 1;
                }

                .dropdownMenu {
                    @extend .status;
                    list-style: none;
                    flex-direction: column;
                    align-items: flex-start;
                    position: absolute;
                    background: #0B3544;

                    .item {
                        width: 100%;
                        padding-left: 2px;

                        &:hover, &:active {
                            background-color: #1ABFDF;
                        }
                    }
                }
            }

            .label {
                font-size: 13px;
            }

            .recentShips {
                display: flex;
                flex-grow: 1;
                flex-direction: row-reverse;
                justify-content: flex-end;
                margin-bottom: 5px;
                min-height: 52px;

                .cirle {
                    width: 52px;
                    height: 52px;
                    border: 1px solid;
                    border-radius: 50%;
                    border-bottom: 5px solid;
                    text-align: center;
                    margin-right: 15px;

                    .brand {
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 20px;
                    }

                    .ship {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
            }

            .brandsShips {
                display: flex;
                flex-direction: column;
                color: rgba(255,255,255,.5);
                overflow-y: auto;
                height: 35px;

                &.withoutRecent {
                    height: 127px;
                }

                .brand {
                    border: 1px solid rgba(255,255,255,.5);
                    border-radius: 5px;
                    border-left: 4px solid #1ABFDF;
                    font-size: 14px;
                    padding: 3px 5px;
                    margin-bottom: 5px;
                }

                .ships {
                    list-style: none;
                    padding: 0 5px;

                    li {
                        border-bottom: 1px solid;
                    }
                }
            }
        }
    }
}

/* ----------- iPhone 4, 4S, 5, 5S, 5C and 5SE----------- */
/* Portrait */
@media only screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .txtsearch {
        width: 100px;
    }
    body div.slideHomePanel .content .recentShips .cirle {
        margin-right: 10px;
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait */
@media only screen and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .txtsearch {
        width: 100px;
    }
    body div.slideHomePanel .content .recentShips .cirle {
        margin-right: 10px;
    }
}
