@font-face {
  font-family: 'open_sans_light';
  src: url(./fonts/OpenSans-Light.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {font-family:"Open Sans Bold";src:url("./fonts/OpenSans-Bold.eot?") format("eot"),url("./fonts/OpenSans-Bold.woff") format("woff"),url("./fonts/OpenSans-Bold.ttf") format("truetype");font-weight:normal;font-style:normal;}
@font-face {
  font-family: 'open_sanscondensed_light';
  src: url(./fonts/opensans-condlight-webfont.eot);
  src: url(./fonts/opensans-condlight-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condlight-webfont.woff2) format('woff2'), url(./fonts/opensans-condlight-webfont.woff) format('woff'), url(./fonts/opensans-condlight-webfont.ttf) format('truetype'), url(./fonts/opensans-condlight-webfont.svg#open_sanscondensed_light) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sans_condensedbold';
  src: url(./fonts/opensans-condbold-webfont.eot);
  src: url(./fonts/opensans-condbold-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condbold-webfont.woff2) format('woff2'), url(./fonts/opensans-condbold-webfont.woff) format('woff'), url(./fonts/opensans-condbold-webfont.ttf) format('truetype'), url(./fonts/opensans-condbold-webfont.svg#open_sans_condensedbold) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansCnLtIt';
  src: url(./fonts/opensans-condlightitalic-webfont.eot);
  src: url(./fonts/opensans-condlightitalic-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condlightitalic-webfont.woff2) format('woff2'), url(./fonts/opensans-condlightitalic-webfont.woff) format('woff'), url(./fonts/opensans-condlightitalic-webfont.ttf) format('truetype'), url(./fonts/opensans-condlightitalic-webfont.svg#open_sansCnLtIt) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  /*src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(./fonts/Open_Sans_Condensed/OpenSans-CondLight.ttf) format('truetype');*/
  src: url(./fonts/opensans-condlight-webfont.eot);
  src: url(./fonts/opensans-condlight-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condlight-webfont.woff2) format('woff2'), url(./fonts/opensans-condlight-webfont.woff) format('woff'), url(./fonts/opensans-condlight-webfont.ttf) format('truetype'), url(./fonts/opensans-condlight-webfont.svg#open_sanscondensed_light) format('svg');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  /*src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(./fonts/Open_Sans_Condensed/OpenSans-CondBold.ttf) format('truetype');*/
  src: url(./fonts/opensans-condbold-webfont.eot);
  src: url(./fonts/opensans-condbold-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condbold-webfont.woff2) format('woff2'), url(./fonts/opensans-condbold-webfont.woff) format('woff'), url(./fonts/opensans-condbold-webfont.ttf) format('truetype'), url(./fonts/opensans-condbold-webfont.svg#open_sans_condensedbold) format('svg');
}
@font-face {font-family:"Ubuntu Bold";src:url("./fonts/Ubuntu-Bold.eot?") format("eot"),url("./fonts/Ubuntu-Bold.woff") format("woff"),url("./fonts/Ubuntu-Bold.ttf") format("truetype");font-weight:normal;font-style:normal;}

a{
  text-decoration: none;
  color: #FFFFFF;
}
body, .loadingbg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.loadingbg {
  background-color: #083242;
  opacity: 0.9;
  z-index: 9999;
}
.loadingbg img {
  position: relative;
  left: 50%;
  margin-left: -32px;
  top: 50%;
  margin-top: -32px;
}
#viewToShipButton{
  display: inline-block;
  position: absolute;
  right: 13px;
  bottom: 250px;
  width: 40px;
}
#viewToShipButton i{
  font-size: 23px;
  color: white;
  display: block;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;
}
#viewToShipButton .circleShipBtn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #12425A;
  box-shadow: rgba(0,0,0,0.16) 2px 2px 2px;
}
#viewToShipButton .circleAlertBtn{
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1ABFDF;
  box-shadow: rgba(0,0,0,0.16) 2px 2px 2px;
}
#viewToShipButton.hideAlertButton .circleAlertBtn {
    display: none;
}
#root, .App{
  height: 100%;
  overflow: hidden;
}
body header nav.nav-bar {
    background-color: #083242;
    border-radius: 0px;
    border: none;
    margin-bottom: 0px;
    border-bottom: 5px solid #1ABFDF;
    text-align: center;
    height: 56px;
    /*display: flex;*/
}
    body header nav.nav-bar .back {
        font-size: 40px;
        position: absolute;
        left: 0;
        color: white;
    }

    body header nav.nav-bar.navbar-brand {
        padding: 0px;
        height: 51px;
        position: absolute;
        left: 0;
    }
        body header nav.nav-bar .navbar-brand img {
            height: 35px;
            margin: 8px;
        }
    body header nav.nav-bar .title {
        color: #FFFFFF;
        font-size: 18px;
        text-transform: uppercase;
        font-family: open_sans_condensedbold, sans-serif;
        line-height: 51px;
        /*margin-left: auto;
        margin-right: auto;*/
    }

body #contentBody {
  height: calc(100% - 117.5px);
  overflow: hidden;
}
body #map, body #homemap {
    height: 100%;
    color: white;
}
body div.slidePanel{
  position: absolute;
  width: 100%;
  background: #12425A;
  height: 223px;
  z-index: 100;
  top: calc(100% - 228px);
  transition: all 1.5s ease 0s;
  color: white;
}
body div.slidePanel.small{
  height: 65px;
  top: calc(100% - 120px);
}
body div.slidePanel .header{
  background-color: rgba(8,50,66,0.72);
  height: 157px;
  padding-top: 5px;
}
body div.slidePanel .header.headersmall {
    height: 50px;
}
body div.slidePanel .header .btnBack{
  float: left;
  font-size: 24px;
  margin-left: 5px;
  cursor: pointer;
}
body div.slidePanel .header .btnClose{
  float: right;
  margin: 5px 10px;
  font-size: 17px;
  cursor: pointer;
}
body div.slidePanel .header .alertIcon{
  float: left;
  width: 30%;
  text-align: center;
  margin-top: 13px;
}
body div.slidePanel .header .alertIcon .iconbg{
  width: 78px;
  height: 78px;
  background-color: #083242;
  border-radius: 50%;
  display: inline-block;
}
body div.slidePanel .header .alertDesc{
  float: left;
  margin-top: 20px;
  width: auto;
}
    body div.slidePanel .header .alertDesc .alertTitle {
        font-family: "Ubuntu Bold", sans-serif;
        font-size: 18px;
    }
    body div.slidePanel .header .alertDesc .alertSubTitle {
        font-family: "open_sanscondensed_light", sans-serif;
        font-size: 18px;
        text-transform: capitalize;
    }
body div.slidePanel .header .slideButton{
  position: relative;
  top: -20px;
  background-color: #1a7b91;
  font-size: 25px;
 /* opacity: 0.4;*/
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
body div.slidePanel .header .slideButton.hide{
  opacity: 0;
}
body div.slidePanel.open .header .slideButton{
  top: auto;
}
body div.slidePanel .header .slideButton::before{
  top: 5px;
  left: 5px;
  position: relative;
}
body div.slidePanel.open {
  top: 56px;
  transition: all 1.5s ease 0s;
  overflow-y: auto;
  height: calc(100% - 117px);
}
body div.slidePanel.open .content{
  display: block;
}
body div.slidePanel .content{
  display: none;
}
body div.slidePanel .panelfooter{
  height: 30px;
}
body div.slidePanel .engineContent{
  padding: 20px 0px 10px 13px;
}
    body div.slidePanel .engineTitle {
        font-family: "Open Sans Bold", sans-serif;
        text-transform: uppercase;
        font-size: 14px;
        margin: 5px 0px;
    }
    body div.slidePanel .engineDate {
        font-family: "open_sansCnLtIt", sans-serif;
        font-size: 12px;
        margin: 5px 13px 5px 0px;
        opacity: 0.54;
        float: right;
    }
body div.slidePanel .box{
padding: 13px;
color: #FFFFFF;
}
    body div.slidePanel .box .boxTitle {
        font-family: "Open Sans Bold", sans-serif;
        text-transform: uppercase;
        font-size: 14px;
        margin: 5px;
    }
body div.slidePanel .box .boxContentAlert{
  border: 1px solid #197288;
  border-top: 6px solid #197288;
  border-radius: 6px;
  min-height: 60px;
}
    body div.slidePanel .box .boxContentAlert .div-table {
        font-family: open_sans_light, sans-serif;
        display: table;
        width: 100%;
        border-spacing: 0px; /* cellspacing:poor IE support for  this */
    }
body div.slidePanel .box .boxContentAlert .div-table-row-header {
  display: table-header-group;
  border-bottom: 1px solid rgba(255,255,255,0.76);
  width: auto;
  clear: both;
  line-height: 30px;
  font-weight: bold;
}
body div.slidePanel .box .boxContentAlert .div-table-row {
  display: table-row;
  border-bottom: 1px solid rgba(255,255,255,0.76);
  width: auto;
  clear: both;
  line-height: 30px;
}
body div.slidePanel .box .boxContentAlert .div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;         
  font-size: 14px;
  min-width: 80px;   
  margin-left: 10px;   
}
body div.slidePanel .box .boxContentAlert .div-table-cell {
    display: table-cell;
    font-size: 14px;
    min-width: 60px;
    padding-left: 5px;
    padding-right: 2px;
}
body div.slidePanel .box .boxContentAlert .div-table-col.date {
  min-width: 130px;    
}
body div.slidePanel .box .boxContent{
  border: 1px solid #197288;
  border-top: 6px solid #197288;
  border-radius: 6px;
  padding: 0px;
}

    body div.slidePanel .box .boxContent .div-table {
        font-family: open_sans_light, sans-serif;
        display: table;
        width: 100%;
        border-spacing: 5px; /* cellspacing:poor IE support for  this */
    }
body div.slidePanel .box .boxContent .div-table-row {
  display: table-row;
  width: auto;
  clear: both;
  line-height: 30px;
}
body div.slidePanel .box .boxContent .div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;         
  font-size: 14px;        
}
body div.slidePanel .box .boxContent .div-table-col .thresholds {
    float: right;
    margin-right: 5px;
    font-family: open_sans_light, sans-serif;
    font-weight: normal;
}
body div.slidePanel .box .boxContent .div-table-col .MTSource {
    float: right;
    margin-right: 5px;
    font-size: 11px;
    border-radius: 4px;
    color: white;
    padding-right: 4px;
    border: 1px solid white;
    float: right;
    line-height: 18px;
    margin: -1px -3px;
}
body div.slidePanel .box .boxContent .div-table-col .MTSource i {
    font-size: 15px;
    line-height: 20px;
}
body div.slidePanel .box .boxContent .div-table-col span{
  margin-left: 10px;  
  font-weight: bold;     
}
body div.slidePanel .box .boxContent .div-table-col.left {
  width: 40%; 
  text-align: right;        
}
body div.slidePanel .box .boxContent .div-table-col.p60{
  width: 60%; 
}
body div.slidePanel .box .boxContent .div-table-col.p40{
  width: 40%; 
}
body div.slidePanel .box .boxContent .div-table-col.p33{
  width: 33.33%; 
}
.red{
  background-color: #CE3538;
}
.yellow{
  background-color: #F6F388;
  color: #083242;
}
.green{
  background-color: #63C563;
}
.gray{
  background-color: #b2b2b2;
}
.border-red{
  border-color: #CE3538;
}
.border-yellow{
  border-color: #F6F388;
}
.border-green{
  border-color: #63C563;
}
.border-gray{
  border-color: #b2b2b2;
}
#usernotallowed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

}
#usernotallowed .content {
  color: white;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  text-align: center;

  background: rgba(64,169,223,1);
  background: -moz-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(64,169,223,1)), color-stop(50%, rgba(18,87,114,1)), color-stop(100%, rgba(19,44,61,1)));
  background: -webkit-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: -o-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: linear-gradient(135deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40a9df', endColorstr='#132c3d', GradientType=1 );
}
#usernotallowed .content .logo {
  background-image: url('images/neptune-platform.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  height: 180px;
  margin-top: 60px;
}
#usernotallowed .content .user{
  margin-top: 50px;
  margin-bottom: 20px;
}
#usernotallowed .content .user div{
  line-height: 30px;
}
#usernotallowed .content .attention{
  font-size: 100px;
}
#usernotallowed .content .message{
  margin-top: 20px;
}
.ol-mouse-position {
    color: white !important;
}