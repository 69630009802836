body #alerts {
  height: 100%;
  width: 100%;
  top: 56px;
  position: absolute;
  background: #40a9df;
  background: -moz-linear-gradient(-45deg, #40a9df 0%, #125772 50%, #132c3d 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #40a9df), color-stop(50%, #125772), color-stop(100%, #132c3d));
  background: -webkit-linear-gradient(-45deg, #40a9df 0%, #125772 50%, #132c3d 100%);
  background: -o-linear-gradient(-45deg, #40a9df 0%, #125772 50%, #132c3d 100%);
  background: -ms-linear-gradient(-45deg, #40a9df 0%, #125772 50%, #132c3d 100%);
  background: linear-gradient(135deg, #40a9df 0%, #125772 50%, #132c3d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40a9df', endColorstr='#132c3d', GradientType=1 ); }
  body #alerts .title-content {
    display: flex;
    align-items: center; }
    body #alerts .title-content.title-border {
      border-bottom: 1px solid #697f8a; }
    body #alerts .title-content .title {
      color: #8eb6cb;
      text-transform: uppercase;
      line-height: 29px;
      margin: 5px 0;
      text-align: center;
      flex-grow: 1;
      padding-left: 35px; }
    body #alerts .title-content .iconFilter {
      margin-left: auto;
      padding: 5px;
      font-size: 20px;
      margin-right: 5px; }
  body #alerts ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100% - 157px); }
    body #alerts ul li {
      background-color: #0c3142;
      min-height: 98px;
      border: 1px solid #697f8a;
      border-radius: 5px;
      margin: 10px;
      display: flex;
      text-align: left;
      padding-left: 15px;
      cursor: pointer; }
      body #alerts ul li i[class^="nepfont_"] {
        font-size: 19px;
        vertical-align: middle;
        margin: 0px 4px 0px 0px; }
      body #alerts ul li i.nepfont_not-managed {
        color: #ff0e0c; }
      body #alerts ul li i.nepfont_closed {
        color: #0eb452; }
      body #alerts ul li .alertContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
        body #alerts ul li .alertContent .alertValue {
          color: white;
          font-weight: bold; }
      body #alerts ul li .arrowButton {
        margin: auto 0 auto auto;
        cursor: pointer; }
        body #alerts ul li .arrowButton i {
          color: white;
          font-size: 40px; }
  body #alerts .switchContainer {
    margin: 10px;
    background-color: #1e404c;
    border: 1px solid #55727b;
    border-radius: 20px;
    padding: 3px; }
    body #alerts .switchContainer .toggle-switch-inner:before {
      background-color: #93C451; }
    body #alerts .switchContainer.disabled {
      opacity: 0.6; }
      body #alerts .switchContainer.disabled .toggle-switch-inner:before {
        background-color: #445b62; }
  body #alerts .buttonContent {
    display: flex; }
  body #alerts button {
    background-color: #528595;
    border: 1px solid #1e404c;
    border-radius: 5px;
    color: white;
    padding: 5px 8px;
    font-weight: bold;
    margin: 20px 10px auto auto; }
