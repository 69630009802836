.shipImage {
    /*margin-left: 50px;*/
    /* margin-top: 20px; */
    /*float: left;*/
    /*width: 35%;*/
    text-align: center;
}
.header .shipStatusContent {
    display: flex;
    flex-direction: column;
    margin-top: -20px;
}
.shipImage img {
    width: 279px;
   height: 61px;
/*     border: 3px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 2px 2px 3px #171716;*/
}
.navStatus {
    font-family: open_sanscondensed_light, sans-serif;
    color: #FFF;
    font-size: 20px;
    /*margin-top: 35px;*/
    margin-left: auto;
    margin-right: 20px;
    margin-bottom: 15px;
}
.navStatus i{
    font-size: 25px;
    margin-right: 10px;
    color: #FFFFFF;
    opacity: 0.36;
}
/*.content{
     overflow-y: auto;
    height: calc(100% - 280px); 
}*/
.content .title {
    font-family: open_sans_light, sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
    height: 30px;
    border-bottom: 1px solid rgba(255,255,255,0.36);
}
.loading{
    text-align: center;
    margin-top: 50px;
    color: white;
}