.mobileButton {
    background-color: #18546E;
    border: 1px solid #1DB7CC;
    padding: 8px;
    border-radius: 20px;
    font-size: 18px;
    color: white;
    margin-top: 10px;
    max-width: 317px;
    min-width: 200px;
    text-align: center;

    img, i {
        margin-right: 8px;
        font-size: 21px;
        vertical-align: middle;
    }

    &:hover {
        background-color: #1DB7CC;
    }
}
