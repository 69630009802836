@font-face {
  font-family: 'nepfont';
  src:  url('fonts/nepfont.eot?35q6w1');
  src:  url('fonts/nepfont.eot?35q6w1#iefix') format('embedded-opentype'),
    url('fonts/nepfont.woff2?35q6w1') format('woff2'),
    url('fonts/nepfont.ttf?35q6w1') format('truetype'),
    url('fonts/nepfont.woff?35q6w1') format('woff'),
    url('fonts/nepfont.svg?35q6w1#nepfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="nepfont_"], [class*=" nepfont_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nepfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nepfont_weather-alerts:before {
  content: "\ea03";
}
.nepfont_optionPanel:before {
  content: "\ea02";
}
.nepfont_download_xlsx:before {
  content: "\ea01";
}
.nepfont_compass:before {
  content: "\e9ff";
}
.nepfont_edit:before {
  content: "\ea00";
}
.nepfont_port-agent-info:before {
  content: "\e9fb";
}
.nepfont_port-info1:before {
  content: "\e9fc";
}
.nepfont_port-status:before {
  content: "\e9fd";
}
.nepfont_port-webcam:before {
  content: "\e9fe";
}
.nepfont_message:before {
  content: "\e9fa";
}
.nepfont_compare-error:before {
  content: "\e9f7";
}
.nepfont_file-error:before {
  content: "\e9f8";
}
.nepfont_import-error:before {
  content: "\e9f9";
}
.nepfont_dashboard-status:before {
  content: "\e9f2";
}
.nepfont_elevators-status:before {
  content: "\e9f3";
}
.nepfont_environmental:before {
  content: "\e9f4";
}
.nepfont_machinery-status:before {
  content: "\e9f5";
}
.nepfont_pool-status:before {
  content: "\e9f6";
}
.nepfont_csv:before {
  content: "\e9ee";
}
.nepfont_genera-video:before {
  content: "\e9ef";
}
.nepfont_video-list:before {
  content: "\e9f0";
}
.nepfont_flusso-nautical:before {
  content: "\e9f1";
}
.nepfont_visibility-sensor:before {
  content: "\e9ed";
}
.nepfont_humidity1:before {
  content: "\e9e7";
}
.nepfont_pressure:before {
  content: "\e9e8";
}
.nepfont_temperature1:before {
  content: "\e9e9";
}
.nepfont_water-level-trend:before {
  content: "\e9ea";
}
.nepfont_wind-gust-direction:before {
  content: "\e9eb";
}
.nepfont_flag-port:before {
  content: "\e9e6";
}
.nepfont_water-level:before {
  content: "\e9da";
}
.nepfont_speed:before {
  content: "\e9db";
}
.nepfont_direction:before {
  content: "\e9dc";
}
.nepfont_level:before {
  content: "\e9dd";
}
.nepfont_wave-height:before {
  content: "\e9de";
}
.nepfont_wave-period:before {
  content: "\e9df";
}
.nepfont_wave-direction:before {
  content: "\e9e0";
}
.nepfont_sea-state:before {
  content: "\e9e1";
}
.nepfont_water-temperature:before {
  content: "\e9e2";
}
.nepfont_wind-speed:before {
  content: "\e9e3";
}
.nepfont_wind-direction:before {
  content: "\e9e4";
}
.nepfont_wind-gust:before {
  content: "\e9e5";
}
.nepfont_limit:before {
  content: "\e9d4";
}
.nepfont_port:before {
  content: "\e9d5";
}
.nepfont_console:before {
  content: "\e9d6";
}
.nepfont_certificates:before {
  content: "\e9d7";
}
.nepfont_statements:before {
  content: "\e9d8";
}
.nepfont_statements2:before {
  content: "\e9d9";
}
.nepfont_health-status:before {
  content: "\e9ec";
}
.nepfont_marine-traffic:before {
  content: "\e9d3";
}
.nepfont_sun:before {
  content: "\e9d2";
}
.nepfont_detach:before {
  content: "\e9cf";
}
.nepfont_maximize:before {
  content: "\e9d0";
}
.nepfont_minimize:before {
  content: "\e9d1";
}
.nepfont_radar:before {
  content: "\e9ce";
}
.nepfont_link-belbook:before {
  content: "\e9cd";
}
.nepfont_send-file:before {
  content: "\e9ca";
}
.nepfont_add-masterdata:before {
  content: "\e9cb";
}
.nepfont_add-masterlist:before {
  content: "\e9cc";
}
.nepfont_bellbook:before {
  content: "\e9c7";
}
.nepfont_status-board:before {
  content: "\e9c8";
}
.nepfont_new_record:before {
  content: "\e9c9";
}
.nepfont_attention_item:before {
  content: "\e9c5";
}
.nepfont_status_item:before {
  content: "\e9c6";
}
.nepfont_time-content:before {
  content: "\e9ba";
}
.nepfont_attachment:before {
  content: "\e9bb";
}
.nepfont_content:before {
  content: "\e9be";
}
.nepfont_time:before {
  content: "\e9c1";
}
.nepfont_EEE-alarms:before {
  content: "\e9c4";
}
.nepfont_LM-console:before {
  content: "\e9c2";
}
.nepfont_ports:before {
  content: "\e9c3";
}
.nepfont_kit5-arrow-down:before {
  content: "\e9b3";
}
.nepfont_kit5-arrow-left:before {
  content: "\e9b4";
}
.nepfont_kit5-arrow-right:before {
  content: "\e9b5";
}
.nepfont_kit5-arrow-up:before {
  content: "\e9b8";
}
.nepfont_filter-list:before {
  content: "\e9bc";
}
.nepfont_night:before {
  content: "\e9bd";
}
.nepfont_search:before {
  content: "\e9c0";
}
.nepfont_clock-error:before {
  content: "\e9ae";
}
.nepfont_radio-button-off:before {
  content: "\e9af";
}
.nepfont_radio-button-on:before {
  content: "\e9b0";
}
.nepfont_switch-off:before {
  content: "\e9b1";
}
.nepfont_switch-on:before {
  content: "\e9b2";
}
.nepfont_not-available:before {
  content: "\e9ad";
}
.nepfont_alert-off:before {
  content: "\e9a9";
}
.nepfont_alert-on:before {
  content: "\e9aa";
}
.nepfont_my-ticket:before {
  content: "\e9ab";
}
.nepfont_hide-element:before {
  content: "\e9a8";
}
.nepfont_owner:before {
  content: "\e9b6";
}
.nepfont_show-element:before {
  content: "\e9b7";
}
.nepfont_user2:before {
  content: "\e9bf";
}
.nepfont_full-screen:before {
  content: "\e9a7";
}
.nepfont_live-dashboard1:before {
  content: "\e9a4";
}
.nepfont_live-dashboard2:before {
  content: "\e9a5";
}
.nepfont_live-dashboard3:before {
  content: "\e9a6";
}
.nepfont_location-info:before {
  content: "\e9a3";
}
.nepfont_manning-level:before {
  content: "\e954";
}
.nepfont_closed-status:before {
  content: "\e955";
}
.nepfont_normal-status:before {
  content: "\e956";
}
.nepfont_voyage-pop:before {
  content: "\e957";
}
.nepfont_export-pop:before {
  content: "\e99f";
}
.nepfont_map-pop:before {
  content: "\e9a0";
}
.nepfont_preview-map:before {
  content: "\e9a1";
}
.nepfont_layer:before {
  content: "\e952";
}
.nepfont_wtd:before {
  content: "\e953";
}
.nepfont_export:before {
  content: "\e999";
}
.nepfont_import:before {
  content: "\e99a";
}
.nepfont_recycle:before {
  content: "\e99b";
}
.nepfont_generic-file:before {
  content: "\e99c";
}
.nepfont_video-file:before {
  content: "\e99d";
}
.nepfont_autocad-file:before {
  content: "\e994";
}
.nepfont_excel-file:before {
  content: "\e995";
}
.nepfont_image-file:before {
  content: "\e996";
}
.nepfont_pdf-file:before {
  content: "\e997";
}
.nepfont_word-file:before {
  content: "\e998";
}
.nepfont_new:before {
  content: "\e993";
}
.nepfont_expand:before {
  content: "\e98f";
}
.nepfont_plus-square:before {
  content: "\e990";
}
.nepfont_minus-square:before {
  content: "\e992";
}
.nepfont_switc:before {
  content: "\e991";
}
.nepfont_clock-delay:before {
  content: "\e970";
}
.nepfont_move:before {
  content: "\e971";
}
.nepfont_state-wtd:before {
  content: "\e972";
}
.nepfont_empty-filters:before {
  content: "\e974";
}
.nepfont_no-filters:before {
  content: "\e96d";
}
.nepfont_normal-filters:before {
  content: "\e96e";
}
.nepfont_editor:before {
  content: "\e9ac";
}
.nepfont_clone:before {
  content: "\e973";
}
.nepfont_clone-edit:before {
  content: "\e98d";
}
.nepfont_rename:before {
  content: "\e98e";
}
.nepfont_note:before {
  content: "\e96f";
}
.nepfont_delete-record:before {
  content: "\e98a";
}
.nepfont_delete-record2:before {
  content: "\e98b";
}
.nepfont_view-record:before {
  content: "\e98c";
}
.nepfont_bar-chart:before {
  content: "\e986";
}
.nepfont_line-chart:before {
  content: "\e987";
}
.nepfont_pie-chart:before {
  content: "\e988";
}
.nepfont_world-map:before {
  content: "\e989";
}
.nepfont_temperature:before {
  content: "\e975";
}
.nepfont_humidity:before {
  content: "\e976";
}
.nepfont_visibility:before {
  content: "\e977";
}
.nepfont_blinking:before {
  content: "\e978";
}
.nepfont_inizio:before {
  content: "\e979";
}
.nepfont_indietro:before {
  content: "\e97a";
}
.nepfont_back:before {
  content: "\e97b";
}
.nepfont_clock:before {
  content: "\e97c";
}
.nepfont_play:before {
  content: "\e97d";
}
.nepfont_avanti:before {
  content: "\e97e";
}
.nepfont_fine:before {
  content: "\e97f";
}
.nepfont_puntina:before {
  content: "\e980";
}
.nepfont_star:before {
  content: "\e981";
}
.nepfont_star2:before {
  content: "\e982";
}
.nepfont_navimeteo:before {
  content: "\e983";
}
.nepfont_block-mode:before {
  content: "\e9b9";
}
.nepfont_list-icon:before {
  content: "\e96a";
}
.nepfont_list-table:before {
  content: "\e96b";
}
.nepfont_false-admin:before {
  content: "\e96c";
}
.nepfont_wifi:before {
  content: "\e969";
}
.nepfont_sort:before {
  content: "\e965";
}
.nepfont_sort_selected_down:before {
  content: "\e966";
}
.nepfont_sort_selected_top:before {
  content: "\e967";
}
.nepfont_meteo-info:before {
  content: "\e932";
}
.nepfont_weather-status:before {
  content: "\e985";
}
.nepfont_show-image:before {
  content: "\e984";
}
.nepfont_report_detail:before {
  content: "\e964";
}
.nepfont_add_ball:before {
  content: "\e95b";
}
.nepfont_add_report:before {
  content: "\e95c";
}
.nepfont_add_user:before {
  content: "\e95d";
}
.nepfont_group:before {
  content: "\e95e";
}
.nepfont_group2:before {
  content: "\e95f";
}
.nepfont_manage_permissions:before {
  content: "\e960";
}
.nepfont_port-info:before {
  content: "\e934";
}
.nepfont_refresh:before {
  content: "\e961";
}
.nepfont_refresh_big:before {
  content: "\e962";
}
.nepfont_refresh_small:before {
  content: "\e963";
}
.nepfont_question:before {
  content: "\e95a";
}
.nepfont_acknowledged:before {
  content: "\e91d";
}
.nepfont_closed:before {
  content: "\e92c";
}
.nepfont_false-alarm:before {
  content: "\e930";
}
.nepfont_not-managed:before {
  content: "\e933";
}
.nepfont_active-alarms:before {
  content: "\e922";
}
.nepfont_alarms:before {
  content: "\e92b";
}
.nepfont_cruise-info:before {
  content: "\e92e";
}
.nepfont_dg-egcs:before {
  content: "\e92f";
}
.nepfont_ship-info:before {
  content: "\e950";
}
.nepfont_satellite:before {
  content: "\e935";
}
.nepfont_actual-vsSNA:before {
  content: "\e92a";
}
.nepfont_cpa:before {
  content: "\e92d";
}
.nepfont_scrubber-SO2CO2 .path1:before {
  content: "\e936";
  color: ;
}
.nepfont_scrubber-SO2CO2 .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path4:before {
  content: "\e939";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path5:before {
  content: "\e93a";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path6:before {
  content: "\e93b";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path7:before {
  content: "\e93c";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path8:before {
  content: "\e93d";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path9:before {
  content: "\e93e";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path10:before {
  content: "\e93f";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path11:before {
  content: "\e940";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path12:before {
  content: "\e941";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-SO2CO2 .path13:before {
  content: "\e942";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path1:before {
  content: "\e943";
  color: ;
}
.nepfont_scrubber-wm .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path3:before {
  content: "\e945";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path4:before {
  content: "\e946";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path5:before {
  content: "\e947";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path6:before {
  content: "\e948";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path7:before {
  content: "\e949";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path8:before {
  content: "\e94a";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path9:before {
  content: "\e94b";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path10:before {
  content: "\e94c";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path11:before {
  content: "\e94d";
  margin-left: -1em;
  color: ;
}
.nepfont_scrubber-wm .path12:before {
  content: "\e94e";
  margin-left: -1em;
  color: ;
}
.nepfont_ship-automations:before {
  content: "\e94f";
}
.nepfont_ukc:before {
  content: "\e951";
}
.nepfont_vp-vsStandard:before {
  content: "\e958";
}
.nepfont_layer-management:before {
  content: "\e9a2";
}
.nepfont_alert-status:before {
  content: "\e99e";
}
.nepfont_route:before {
  content: "\e926";
}
.nepfont_support:before {
  content: "\e927";
}
.nepfont_platform:before {
  content: "\e925";
}
.nepfont_health:before {
  content: "\e931";
}
.nepfont_collector:before {
  content: "\e968";
}
.nepfont_online-help:before {
  content: "\e959";
}
.nepfont_user:before {
  content: "\e928";
}
.nepfont_utility:before {
  content: "\e929";
}
.nepfont_enne:before {
  content: "\e921";
}
.nepfont_attention:before {
  content: "\e920";
}
.nepfont_link:before {
  content: "\e923";
}
.nepfont_unlink:before {
  content: "\e924";
}
.nepfont_binocolo1:before {
  content: "\e91c";
}
.nepfont_binocolo2:before {
  content: "\e91f";
}
.nepfont_help:before {
  content: "\e91a";
}
.nepfont_info2:before {
  content: "\e91b";
}
.nepfont_info1:before {
  content: "\e91e";
}
.nepfont_close1:before {
  content: "\e918";
}
.nepfont_close2:before {
  content: "\e919";
}
.nepfont_ball:before {
  content: "\e900";
}
.nepfont_kit1-arrow-down:before {
  content: "\e902";
}
.nepfont_kit1-arrow-left:before {
  content: "\e903";
}
.nepfont_kit1-arrow-right:before {
  content: "\e904";
}
.nepfont_kit1-arrow-up:before {
  content: "\e905";
}
.nepfont_kit2-arrow-down:before {
  content: "\e906";
}
.nepfont_kit2-arrow-left:before {
  content: "\e907";
}
.nepfont_kit2-arrow-right:before {
  content: "\e908";
}
.nepfont_kit2-arrow-up:before {
  content: "\e909";
}
.nepfont_kit3-arrow-down:before {
  content: "\e90a";
}
.nepfont_kit3-arrow-left:before {
  content: "\e90b";
}
.nepfont_kit3-arrow-right:before {
  content: "\e90c";
}
.nepfont_kit3-arrow-up:before {
  content: "\e90d";
}
.nepfont_kit4-arrow-down:before {
  content: "\e90e";
}
.nepfont_kit4-arrow-left:before {
  content: "\e90f";
}
.nepfont_kit4-arrow-right:before {
  content: "\e910";
}
.nepfont_kit4-arrow-up:before {
  content: "\e911";
}
.nepfont_save:before {
  content: "\e912";
}
.nepfont_ship:before {
  content: "\e913";
}
.nepfont_home:before {
  content: "\e901";
}
.nepfont_state-ceck:before {
  content: "\e914";
}
.nepfont_state-edit:before {
  content: "\e915";
}
.nepfont_state-hand:before {
  content: "\e916";
}
.nepfont_state-page:before {
  content: "\e917";
}
