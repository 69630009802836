@font-face {
  font-family: 'nauticalset2';
  src:  url('fonts/nauticalset2.eot?ir45mm');
  src:  url('fonts/nauticalset2.eot?ir45mm#iefix') format('embedded-opentype'),
    url('fonts/nauticalset2.woff2?ir45mm') format('woff2'),
    url('fonts/nauticalset2.ttf?ir45mm') format('truetype'),
    url('fonts/nauticalset2.woff?ir45mm') format('woff'),
    url('fonts/nauticalset2.svg?ir45mm#nauticalset2') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nauticalset2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ns-icon-01:before {
  content: "\61";
}
.ns-icon-02:before {
  content: "\62";
}
.ns-icon-03:before {
  content: "\63";
}
.ns-icon-04:before {
  content: "\64";
}
.ns-icon-05:before {
  content: "\65";
}
.ns-icon-06:before {
  content: "\66";
}
.ns-icon-07:before {
  content: "\67";
}
.ns-icon-08:before {
  content: "\68";
}
.ns-icon-09:before {
  content: "\69";
}
.ns-icon-10:before {
  content: "\6a";
}
.ns-icon-11:before {
  content: "\6b";
}
.ns-icon-12:before {
  content: "\6c";
}
.ns-icon-13:before {
  content: "\6d";
}
.ns-icon-14:before {
  content: "\6e";
}
.ns-icon-15:before {
  content: "\6f";
}
.ns-icon-16:before {
  content: "\70";
}
.ns-icon-17:before {
  content: "\71";
}
.ns-icon-18:before {
  content: "\72";
}
.ns-icon-19:before {
  content: "\73";
}
.ns-icon-20:before {
  content: "\74";
}
.ns-icon-21:before {
  content: "\75";
}
.ns-icon-22:before {
  content: "\76";
}
.ns-icon-23:before {
  content: "\77";
}
.ns-icon-24:before {
  content: "\78";
}
.ns-icon-25:before {
  content: "\79";
}
.ns-icon-26:before {
  content: "\7a";
}
.ns-icon-27:before {
  content: "\59";
}
.ns-icon-28:before {
  content: "\5a";
}
.ns-icon-29:before {
  content: "\e927";
}
.ns-icon-30:before {
  content: "\44";
}
.ns-icon-31:before {
  content: "\45";
}
.ns-icon-32:before {
  content: "\46";
}
.ns-icon-33:before {
  content: "\47";
}
.ns-icon-34:before {
  content: "\48";
}
.ns-icon-35:before {
  content: "\49";
}
.ns-icon-36:before {
  content: "\4a";
}
.ns-icon-37:before {
  content: "\4b";
}
.ns-icon-38:before {
  content: "\4c";
}
.ns-icon-39:before {
  content: "\4d";
}
.ns-icon-40:before {
  content: "\4e";
}
.ns-icon-41:before {
  content: "\4f";
}
.ns-icon-42:before {
  content: "\50";
}
.ns-icon-43:before {
  content: "\51";
}
.ns-icon-44:before {
  content: "\52";
}
.ns-icon-45:before {
  content: "\53";
}
.ns-icon-46:before {
  content: "\54";
}
.ns-icon-47:before {
  content: "\55";
}
.ns-icon-48:before {
  content: "\56";
}
.ns-icon-49:before {
  content: "\41";
}
.ns-icon-50:before {
  content: "\42";
}
.ns-icon-51:before {
  content: "\43";
}
.ns-icon-52:before {
  content: "\57";
}
.ns-icon-53:before {
  content: "\58";
}
.ns-icon-57:before {
  content: "\21";
}
.ns-pushpin:before {
  content: "\a3";
}
.ns-sunrise:before {
  content: "\24";
}
.ns-report:before {
  content: "\25";
}
.ns-colorpalette:before {
  content: "\26";
}
.ns-editnote:before {
  content: "\3f";
}
.ns-view:before {
  content: "\31";
}
.ns-book-button:before {
  content: "\32";
}
.ns-edit-button:before {
  content: "\33";
}
.ns-gear-button:before {
  content: "\34";
}
.ns-info-button:before {
  content: "\35";
}
.ns-hystory:before {
  content: "\36";
}
.ns-moon:before {
  content: "\37";
}
.ns-moon-night:before {
  content: "\38";
}
