@font-face {
  font-family: 'open_sans_light';
  src: url(./fonts/OpenSans-Light.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {font-family:"Open Sans Bold";src:url("./fonts/OpenSans-Bold.eot?") format("eot"),url("./fonts/OpenSans-Bold.woff") format("woff"),url("./fonts/OpenSans-Bold.ttf") format("truetype");font-weight:normal;font-style:normal;}
@font-face {
  font-family: 'open_sanscondensed_light';
  src: url(./fonts/opensans-condlight-webfont.eot);
  src: url(./fonts/opensans-condlight-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condlight-webfont.woff2) format('woff2'), url(./fonts/opensans-condlight-webfont.woff) format('woff'), url(./fonts/opensans-condlight-webfont.ttf) format('truetype'), url(./fonts/opensans-condlight-webfont.svg#open_sanscondensed_light) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sans_condensedbold';
  src: url(./fonts/opensans-condbold-webfont.eot);
  src: url(./fonts/opensans-condbold-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condbold-webfont.woff2) format('woff2'), url(./fonts/opensans-condbold-webfont.woff) format('woff'), url(./fonts/opensans-condbold-webfont.ttf) format('truetype'), url(./fonts/opensans-condbold-webfont.svg#open_sans_condensedbold) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansCnLtIt';
  src: url(./fonts/opensans-condlightitalic-webfont.eot);
  src: url(./fonts/opensans-condlightitalic-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condlightitalic-webfont.woff2) format('woff2'), url(./fonts/opensans-condlightitalic-webfont.woff) format('woff'), url(./fonts/opensans-condlightitalic-webfont.ttf) format('truetype'), url(./fonts/opensans-condlightitalic-webfont.svg#open_sansCnLtIt) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  /*src: local('Open Sans Cond Light'), local('OpenSans-CondensedLight'), url(./fonts/Open_Sans_Condensed/OpenSans-CondLight.ttf) format('truetype');*/
  src: url(./fonts/opensans-condlight-webfont.eot);
  src: url(./fonts/opensans-condlight-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condlight-webfont.woff2) format('woff2'), url(./fonts/opensans-condlight-webfont.woff) format('woff'), url(./fonts/opensans-condlight-webfont.ttf) format('truetype'), url(./fonts/opensans-condlight-webfont.svg#open_sanscondensed_light) format('svg');
}
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  /*src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'), url(./fonts/Open_Sans_Condensed/OpenSans-CondBold.ttf) format('truetype');*/
  src: url(./fonts/opensans-condbold-webfont.eot);
  src: url(./fonts/opensans-condbold-webfont.eot?#iefix) format('embedded-opentype'), url(./fonts/opensans-condbold-webfont.woff2) format('woff2'), url(./fonts/opensans-condbold-webfont.woff) format('woff'), url(./fonts/opensans-condbold-webfont.ttf) format('truetype'), url(./fonts/opensans-condbold-webfont.svg#open_sans_condensedbold) format('svg');
}
@font-face {font-family:"Ubuntu Bold";src:url("./fonts/Ubuntu-Bold.eot?") format("eot"),url("./fonts/Ubuntu-Bold.woff") format("woff"),url("./fonts/Ubuntu-Bold.ttf") format("truetype");font-weight:normal;font-style:normal;}

a{
  text-decoration: none;
  color: #FFFFFF;
}
/*body, .loadingbg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}*/
#loadercc {
    background-color: #083242;
    opacity: 0.9;
    z-index: 9999;
}
#loadercc img {
    position: relative;
    left: 0px;
    margin-left: -32px;
    top: 50%;
    margin-top: -32px;
}

#root, .Settings {
    background-color: #083242;
    border-radius: 0px;
    border: none;
    margin-bottom: 1px;
    border-bottom: 0px solid #1ABFDF;
    color: #add8e6;
}
.Settings {
    text-align: center;
    color: #add8e6;
}
body .Settings .nav-bar {
    background-color: #083242;
    border-radius: 0px;
    border: none;
    margin-bottom: 0px;
    border-bottom: 5px solid #1ABFDF;
    text-align: center;
    height: 56px;
}
    body .Settings .nav-bar .navbar-brand {
        padding: 0px;
        height: 51px;
        position: absolute;
        left: 0;
    }
        body .Settings .nav-bar .navbar-brand img {
            height: 35px;
            margin: 8px;
        }
    body .Settings .nav-bar .title {
        color: #FFFFFF;
        font-size: 18px;
        text-transform: uppercase;
        font-family: open_sans_condensedbold, sans-serif;
        line-height: 51px;
    }

body .Settings #contentBody {
  height: calc(100% - 1px);
  overflow: hidden;
}

body .Settings div.contentSettings {
    position: absolute;
    width: 100%;
    z-index: 100;
    color: white;
    top: 56px;
    overflow-y: auto;
    height: calc(100% - 57px);
}

body .Settings div.contentSettings .content {
    display: block;
}
body .Settings div.contentSettings .panelfooter {
    height: 30px;
}

#usernotallowed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

}
#usernotallowed .content {
  color: white;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  text-align: center;

  background: rgba(64,169,223,1);
  background: -moz-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(64,169,223,1)), color-stop(50%, rgba(18,87,114,1)), color-stop(100%, rgba(19,44,61,1)));
  background: -webkit-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: -o-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  background: linear-gradient(135deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40a9df', endColorstr='#132c3d', GradientType=1 );
}
#usernotallowed .content .logo {
  background-image: url('images/neptune-platform.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  height: 180px;
  margin-top: 60px;
}
#usernotallowed .content .user{
  margin-top: 50px;
  margin-bottom: 20px;
}
#usernotallowed .content .user div{
  line-height: 30px;
}
#usernotallowed .content .attention{
  font-size: 100px;
}
#usernotallowed .content .message{
  margin-top: 20px;
}


/*@media screen and (max-width: 1200px) {*/

    .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0.25rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    .collapse.show {
        padding-bottom: 1.25rem;
    }

.TitleBrand.card-header {
    float: left;
    border-right: 1px solid #fff;
    /*border-top: 0.3px dotted #fff;*/
    border-top: 1px solid rgba(255,255,255,.6);
    border-bottom: 1px solid rgba(255,255,255,.6);
}

    .TitleBrand.card-header i {
        font-size: 20px;
        top: 3px;
        position: relative;
    }

    .ToggleBrand {
        width: initial;
    }

    .tcontainer {
        display: table;
        float: right;
        clear: both;
        /*border: 1px solid;*/
        width: 100%;
    }

    .tcontainer:first-child {
        border-top: 1px solid rgba(225,255,255,.6);
    }

    .trow {
        width: 100%;
        /*border: 1px solid #fff;*/
        border-bottom: 1px solid rgba(225,255,255,.6);
        display: table-cell;
    }

    .tleft, .tright, .tmiddle {
        display: table-cell;
    }

    .tleft {
        padding-left: 0px;
    }

    .tmiddle {
        width: 100%;
        text-align: start;
        position: relative;
        top: 0px;
        white-space: nowrap;
        vertical-align: bottom;
    }

    .tright {
        position: relative;
        top: -1px;
    }

    .supsetItems {
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        display: inline-flex;
        background-color: burlywood;
    }

    .setItems {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .CardsElements {
        background-color: #083242;
        background-color: #0000;
    }

    #root .Settings .toggle-switch-inner:before {
        background-color: #93C451;
    }
    #root .Settings .toggle-switch {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }
    .card-header:first-child {
        margin-top: 2px;
    }

    .TitleBrand card-header {
        #height: 33px;
    }

    .card-header {
        padding: 0.05rem 0.25rem;
        /*margin-top: 11px;*/
        background-color: #00000008;
        border-bottom: 1px solid #00000020;
        margin-top: 0px;
        border-left: 5px solid #1ABFDF;
        border-radius: 8px !important;
    }

    .accordion > .card .card-header {
        height: 30px;
        padding-top: 2px;
        margin-bottom: 0px;
    }
    .accordion > .card:not(:first-of-type) .card-header:first-child {
        border-radius: unset; 
    }
    .accordion > .card:not(:first-of-type):not(:last-of-type) {
        border-bottom: unset; 
        border-radius: unset; 
    }
    .accordion > .card:first-of-type {
        border-bottom: unset;
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
    }

    .icontTitle  {
        margin-bottom: 8px;
    }

    .titles {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .titleAlarm {
        margin-left: 10px;
    }

    .titleAlarmMyShip {
        margin-left: 10px;
    }

    .CardsElements.card {
        margin-left: auto;
        margin-right: auto;
        width: 94%;
        border-top: none;
        margin-top: 5px;
    }

    h4, .h4 {
        font-size: 1.2rem;
    }

    .settingsTitle {
        font-size: 24px;
        text-transform: uppercase;
        position: relative;
        top: -40px;
    }

    .TitleBack {
        height: 60px;
        border-bottom: #f0ffff;
        /* border-bottom: #f0ffff; */
        /*background-color: #197288;*/
        background: #a6bac9;
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(166,186,201,1)), color-stop(41%, rgba(141,166,185,1)), color-stop(100%, rgba(104,136,161,1)));
        background: -webkit-linear-gradient(left, #a6bac9 0%, #8da6b9 41%, #6888a1 100%);
        background: -o-linear-gradient(left, rgba(166,186,201,1) 0%, rgba(141,166,185,1) 41%, rgba(104,136,161,1) 100%);
        background: -webkit-gradient(linear, left top, right top, from(#a6bac9), color-stop(41%, #8da6b9), to(#6888a1));
        background: linear-gradient(to right, #6e757b 0%, #628097 41%, #344653 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6bac9', endColorstr='#6888a1', GradientType=1 );
    }
    .sTitleBack {
        /*background-color: #197288;*/
        height: 60px;
        border-bottom: #f0ffff;
        border-radius: 40px;
        position: relative;
        top: 40px;
        background-color: #083242;
    }

    .accordion {
        position: relative;
        top: -55px;
    }
    .contentSettings.accordion {
        background-color: transparent;
    }

    .modal {
        outline: none;
        background: white;
        font-size: 1.6rem;
        width: 76rem;
        max-width: 90%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        z-index: 15;
        min-height: 47rem;
        will-change: transform;
        transform: translate3d(0, 0, 0);
    }

    @media (max-width: 768px) {
        .modal {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    h1 small {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        display: block;
        font-size: 24px;
        color: #fff;
    }

    [data-preview="ToastContainer"] {
        position: relative;
    }

    [data-preview="ToastContainer"]::before {
        filter: blur(10px);
        /*background-image: url("./demo.jpg");*/
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        content: "";
        z-index: 0;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
    }
    .container {
        position: absolute;
        background-color: red;
    }

    .github-button-container {
        margin: 20px 0 0 0;
        text-align: center;
    }

    .btn-container {
        text-align: center;
    }

    .btn-container button {
        outline: 0;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        width: 140px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin: 10px 5px;
        background: #1abc9c;
        display: inline-block;
        text-decoration: none;
        border-radius: 5px;
        box-shadow: 0px 3px 0px 0px #17a88c;
        transition: all 150ms ease-in;
    }

    .btn-container button:hover {
        background: #01a383;
        box-shadow: 0px 3px 0px 0px #008f73;
    }

    .groupBrand {
        transform: scale(0.68); 
        color: #a9a9a9; 
        margin-top: -28px;
        margin-right: -15px;
    }

/*}*/

