.engineDetails .content-table{
    display: table;
    width: 300px;
    border-spacing: 0;
    height: 76px;
    margin: auto;
}
.engineDetails .content-table .col1{
    display: table-cell;
    width: 33.33%;
    text-align: center;
    vertical-align: middle;
}
.engineDetails .content-table .col2{
    display: table-cell;
    width: 33.33%;
    vertical-align: middle;
}
.engineDetails .content-table .col3{
    display: table-cell;
    width: 33.33%;
    vertical-align: middle;
}
    .engineDetails .content-table .aaqsStatus {
        width: 71px;
        height: 22px;
        text-transform: uppercase;
        font-family: open_sans_condensedbold, sans-serif;
        font-size: 12px;
        border-width: 3px;
        border-style: solid;
        border-radius: 15px;
        text-align: center;
        /*line-height: 22px;*/
    }
.engineDetails .label {
    font-family: open_sanscondensed_light, sans-serif;
    font-size: 14px;
}
.engineDetails .value {
    font-family: open_sans_condensedbold, sans-serif;
    font-size: 14px;
}