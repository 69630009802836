@font-face {
  font-family: 'NepMobileFont';
  src:  url('fonts/NepMobileFont.eot?nl9zwd');
  src:  url('fonts/NepMobileFont.eot?nl9zwd#iefix') format('embedded-opentype'),
    url('fonts/NepMobileFont.woff2?nl9zwd') format('woff2'),
    url('fonts/NepMobileFont.ttf?nl9zwd') format('truetype'),
    url('fonts/NepMobileFont.woff?nl9zwd') format('woff'),
    url('fonts/NepMobileFont.svg?nl9zwd#NepMobileFont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'NepMobileFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-live:before {
    content: "\e904";
}

.icon-my_ship:before {
    content: "\e905";
}

.icon-profile:before {
    content: "\e906";
}

.icon-settings:before {
    content: "\e907";
}

.icon-hr:before {
    content: "\e903";
}

.icon-fleet-statistics:before {
    content: "\e900";
}

.icon-port-itinerary:before {
    content: "\e901";
}

.icon-project-status:before {
    content: "\e902";
}

.icon-logo-myship:before {
    content: "\21";
}

.icon-logo-platform:before {
    content: "\22";
}

.icon-alert-info:before {
    content: "\24";
}

.icon-engine-status:before {
    content: "\25";
}

.icon-voyage-info:before {
    content: "\26";
}

.icon-alert-position:before {
    content: "\2f";
}

.icon-lert-position2:before {
    content: "\28";
}

.icon-ship-position:before {
    content: "\29";
}

.icon-layer:before {
    content: "\3d";
}

.icon-esc:before {
    content: "\3f";
}

.icon-list:before {
    content: "\5e";
}

.icon-SingleDG-inPort:before {
    content: "\71";
}

.icon-PortPower-coefficient:before {
    content: "\77";
}

.icon-support:before {
    content: "\65";
}

.icon-release-info:before {
    content: "\72";
}

.icon-asage-rate:before {
    content: "\74";
}

.icon-availability:before {
    content: "\79";
}

.icon-megafono:before {
    content: "\75";
}

.icon-attention:before {
    content: "\69";
}

.icon-eee2:before {
    content: "\6f";
}

.icon-info1:before {
    content: "\70";
}

.icon-info2:before {
    content: "\61";
}

.icon-eee:before {
    content: "\73";
}

.icon-financial:before {
    content: "\64";
}

.icon-hess:before {
    content: "\66";
}

.icon-operational:before {
    content: "\67";
}

.icon-performance:before {
    content: "\68";
}

.icon-crew:before {
    content: "\6a";
}

.icon-port:before {
    content: "\6b";
}

.icon-repair-maintenance:before {
    content: "\6c";
}

.icon-fleet-maintenance:before {
    content: "\f2";
}

.icon-menu:before {
    content: "\7a";
}

.icon-attention-message:before {
    content: "\78";
}

.icon-page:before {
    content: "\63";
}

.icon-hydrodynamic:before {
    content: "\76";
}

.icon-engine-energy:before {
    content: "\62";
}

.icon-environmental:before {
    content: "\6e";
}

.icon-safety-nautical:before {
    content: "\6d";
}

.icon-workspace:before {
    content: "\2c";
}

.icon-bell:before {
    content: "\2e";
}

.icon-bell-active:before {
    content: "\2d";
}

.icon-share:before {
    content: "\3b";
}

.icon-close1:before {
    content: "\3a";
}

.icon-close2:before {
    content: "\5f";
}

.icon-down:before {
    content: "\e7";
}

.icon-up:before {
    content: "\40";
}

.icon-back:before {
    content: "\e0";
}

.icon-next:before {
    content: "\23";
}

.icon-favorite:before {
    content: "\b0";
}

.icon-favorite-active:before {
    content: "\f9";
}

.icon-filter:before {
    content: "\a7";
}

.icon-timone:before {
    content: "\e8";
}

.icon-fire:before {
    content: "\e9";
}

.icon-fuel:before {
    content: "\5b";
}

.icon-nim:before {
    content: "\2b";
}

.icon-lamp:before {
    content: "\2a";
}

.icon-search:before {
    content: "\5d";
}

.icon-lock2:before {
    content: "\27";
}

.icon-lock1:before {
    content: "\ec";
}

.icon-state-ceck:before {
    content: "\31";
}

.icon-kpi:before {
    content: "\32";
}

.icon-ship:before {
    content: "\33";
}

.icon-setting:before {
    content: "\34";
}

.icon-user1:before {
    content: "\35";
}

.icon-user2:before {
    content: "\36";
}

.icon-ship-info:before {
    content: "\37";
}

.icon-cruise-info:before {
    content: "\38";
}

.icon-weather-info:before {
    content: "\39";
}

.icon-automations:before {
    content: "\30";
}

.icon-anchor:before {
    content: "\5c";
}

.icon-egcs:before {
    content: "\7c";
}

