#support.pageContent {
    background: #093244;

    .content {
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;

        .title {
            font-size: 18px;
            text-transform: uppercase;
            border: none;
            margin-bottom: 70px;
        }

        .questiontext {
            color: #1ABFDF;
        }

        .text {
            font-size: 14px;
            color: white;
            margin-top: 5px;

            p {
                margin-bottom: .4em;
            }
        }

        hr {
            border-top: 3px solid #0D6076;
            width: 192px;
            margin: 1.8rem auto;
        }

        .mailsupport {
            background-color: #18546E;
            border: 1px solid #1DB7CC;
            padding: 8px;
            border-radius: 20px;
            font-size: 18px;
            color: white;
            /*margin-top: 30px;*/
            margin-bottom: 3px;

            img {
                margin-right: 8px;
            }
        }

        .logoutbtn {
            margin-top: auto;
            margin-bottom: 15px;
        }
    }
}
