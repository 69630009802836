body #profile.pageContent .content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .title {
        border: none;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .userDetails {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding-top: 15px;

        .label {
            margin-right: 10px;
        }

        .value {
            font-weight: bold;
            margin-left: 10px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 30px;
    }

    .logoutbtn{
        margin-top: auto;
        margin-bottom: 15px;
    }
}
