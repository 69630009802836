.steps {
    padding: 14px 0;
    position: relative;
    margin: auto;
    font-family: open_sans_light, sans-serif;
}
.step {
    padding: 0 20px 24px 50px;
    position: relative;
  }
.step::before {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: #12425A;
    border: 1px solid white;
    border-radius: 15px;
    left: calc(50px / 2);
    transform: translateX(-45%);
    z-index: 2;
  }
  .step.selected::before {
    background-color: white;
  }
  .step::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255,0.76);
    left: calc(50px / 2);
    top: 0;
    z-index: 1;
  }
.step-header .headerText {
    user-select: none;
    font-family: 'open_sans_condensedbold', sans-serif;
    font-size: 18px;
}
    .step-header .headerText .right {
        float: right;
        font-family: open_sans_light, sans-serif;
        font-size: 14px;
    }
  .step-header .subheader {
    user-select: none;
    font-size: 14px;
  }