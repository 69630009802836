.engineBox{
    border: 1px solid #197288;
    border-radius: 6px;
    width: calc(50% - 12px);
    min-height: 98px;
    margin-right: 10px;
    margin-top: 10px;
    float: left;
}
    .engineBox .header-status {
        height: 20px;
        border-bottom: 1px solid #197288;
        font-family: open_sans_condensedbold, sans-serif;
        font-size: 12px;
        text-align: right;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        line-height: 20px;
        padding: 0 5px;
        text-transform: uppercase;
    }
.engineBox .content-table{
    display: table;
    width: 100%;
    border-spacing: 0;
    height: 76px;
}
.engineBox .content-table .col1{
    display: table-cell;
    width: 40%;
    text-align: center;
    vertical-align: middle;
}
.engineBox .content-table .col2{
    display: table-cell;
    width: 60%;
    vertical-align: middle;
}
.engineContent .label {
    font-family: open_sanscondensed_light, sans-serif;
    font-size: 14px;
}
.engineContent .value {
    font-family: open_sans_condensedbold, sans-serif;
    font-size: 14px;
}
.content-table .emptyrow{
    height: 21px;
    display: block;
}
.content-table .dgStatus {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    display: inline-block;
    font-family: open_sans_condensedbold, sans-serif;
    font-size: 18px;
}
