footer {
    background-color: #083242;
    height: 61px;
    border-top: 0.5px solid #1ABEDE;
    z-index: 999;
    position: absolute;
    width: 100%;
    bottom: 0;
}
 footer ul.buttonbar {
    padding: 0;
    margin: 0;
    color: #FFFFFF;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
footer ul.buttonbar li {
    display: block;
    flex: 0 1 100%;
    list-style-type: none;
    text-align: center;
    font-size: 11px;
    padding-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
    footer ul.buttonbar li.disabled {
        opacity: 0.6;
    }
  footer ul.buttonbar li.selected {
    border-bottom: 4px solid #1ABFDF;
    color: #1ABFDF !important;
  }
  footer ul.buttonbar li.selected a, footer ul.buttonbar li.selected a:visited, footer ul.buttonbar li.selected a:hover{
    color: #1ABFDF;
  }
  footer ul.buttonbar li i{
    font-size: 22px;
  }
footer ul.buttonbar li a {
    text-decoration: none;
    color: #FFFFFF;
}