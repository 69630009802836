.updateIssueLabel {
  background-color: #ff7900;
  color: white;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 11px;
  margin-left: 10px;
  height: 18px;
  margin-left: auto;
  margin-top: 3px;
}
