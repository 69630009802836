.egcsIcon{
position: absolute;
}
.egcsIcon .icon-timone{
    font-size: 56px;
    top: 10px;
    position: relative;
    left: 11px;
}
.egcsIcon .icon-egcs{
    font-size: 26px;
    top: 16px;
    position: absolute;
    left: 15px;
    color:white;
}
.sev0{
    color: green;
}
.sev1 {
    color: #FFD91D;
}
.sev2 {
    color: #CE3538;
}
.alertContentMessage{
    text-align: center;
    margin-top: 50%;
    position: relative;
}
.alertDetailClose {
    position: absolute;
    right: 10px;
    font-size: 20px;
}