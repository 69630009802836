body {
    .pageContent {
        color: white;
        display: flex;
        height: 100%;
        background: rgba(64,169,223,1);
        background: -moz-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(64,169,223,1)), color-stop(50%, rgba(18,87,114,1)), color-stop(100%, rgba(19,44,61,1)));
        background: -webkit-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
        background: -o-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
        background: -ms-linear-gradient(-45deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
        background: linear-gradient(135deg, rgba(64,169,223,1) 0%, rgba(18,87,114,1) 50%, rgba(19,44,61,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40a9df', endColorstr='#132c3d', GradientType=1 );


        .content {
            margin: auto;

            .user {
                text-align: center;

                i {
                    font-size: 120px;
                }

                span {
                    font-size: 18px;
                    margin: 10px 0 15px 0;
                    display: block
                }
            }

            .menu {
                padding: 0;
                margin: 0;
                list-style: none;
                text-align: left;
                text-transform: uppercase;

                a {
                    &:hover, &:active, &:visited {
                        text-decoration: none;
                        color: #FFFFFF;
                    }
                }

                .menuItem {
                    border: 1px solid #9ADFEC;
                    background-color: #11374B;
                    margin: 5px;
                    padding: 10px 5px;
                    display: flex;
                    min-width: 300px;
                    cursor: pointer;

                    .icon {
                        font-size: 63px;
                        margin-right: 5px;
                    }

                    .icon-right {
                        font-size: 25px;
                        margin-left: auto;
                        margin-top: auto;
                        margin-bottom: auto
                    }

                    .menuText {
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: 5px;
                        font-size: 23px;
                    }
                }
            }
        }
    }
}
